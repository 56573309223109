<template>
  <main>
    <CAlert color="info" :fade="false" :close-button="true">
      <i class="fas fa-info-circle mr-2" />僅付款狀態為「已付款」或「代收款」且配送狀態為「待出貨」或「準備中」的訂單可進行出貨管理作業。
    </CAlert>
    <CCard>
      <CAlert
        id="ChooseNotice"
        v-if="(ChooseIndex.length > 0)"
        color="info"
        :fade="false"
      >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseIndex.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
        <div class="ButtonGroup">
          <CDropdown size="sm" color="info" placement="bottom-start" class="d-inline-block mr-2">
            <template #toggler-content>
              {{ $t('Order.BulkCreateOrder') }}
            </template>
            <CDropdownItem @click="BulkCreateShipmentOrder('ecpay-logistics')">
              {{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkCreateShipmentOrder('hct-logistics')">
              {{ $t('Logistics.LogisticsSystem.HCT-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkCreateShipmentOrder('kerrytj-logistics')">
              {{ $t('Logistics.LogisticsSystem.KERRYTJ-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkCreateShipmentOrder('sf-logistics')">
              {{ $t('Logistics.LogisticsSystem.SF-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkCreateShipmentOrder('dpex-logistics')">
              {{ $t('Logistics.LogisticsSystem.DPEX-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkCreateShipmentOrder('ucfuship-logistics')">
              {{ $t('Logistics.LogisticsSystem.UCFUSHIP-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkCreateShipmentOrder('jenjan-logistics')">
              {{ $t('Logistics.LogisticsSystem.JENJAN-LOGISTICS') }}
            </CDropdownItem>
          </CDropdown>
          <CDropdown size="sm" color="danger" placement="bottom-start" class="d-inline-block mr-2">
            <template #toggler-content>
              {{ $t('Order.PrintLabel') }}
            </template>
            <CDropdownItem @click="BulkPrintLabelModal('ecpay-logistics')">
              {{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkPrintLabel('hct-logistics')">
              {{ $t('Logistics.LogisticsSystem.HCT-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkPrintLabelModal('kerrytj-logistics')">
              {{ $t('Logistics.LogisticsSystem.KERRYTJ-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkPrintLabelModal('sf-logistics')">
              {{ $t('Logistics.LogisticsSystem.SF-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkPrintLabelModal('dpex-logistics')">
              {{ $t('Logistics.LogisticsSystem.DPEX-LOGISTICS') }}
            </CDropdownItem>
            <CDropdownItem @click="BulkPrintLabelModal('ucfuship-logistics')">
              {{ $t('Logistics.LogisticsSystem.UCFUSHIP-LOGISTICS') }}
            </CDropdownItem>
<!--            <CDropdownItem @click="RedirectPrintShipmentLabel('ecpay-logistics', 'UNIMARTC2C', false)">-->
<!--              {{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }} - {{ $t('Logistics.LogisticsMethods.UNIMARTC2C') }}-->
<!--            </CDropdownItem>-->
<!--            <CDropdownItem @click="RedirectPrintShipmentLabel('ecpay-logistics', 'FAMIC2C', false)">-->
<!--              {{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }} - {{ $t('Logistics.LogisticsMethods.FAMIC2C') }}-->
<!--            </CDropdownItem>-->
<!--            <CDropdownItem @click="RedirectPrintShipmentLabel('ecpay-logistics', 'OKMARTC2C', false)">-->
<!--              {{ $t('Logistics.LogisticsSystem.ECPAY-LOGISTICS') }} - {{ $t('Logistics.LogisticsMethods.OKMARTC2C') }}-->
<!--            </CDropdownItem>-->
          </CDropdown>
          <CDropdown size="sm" color="success" placement="bottom-start" class="d-inline-block mr-2">
            <template #toggler-content>
              {{ $t('Order.PrintShipmentList') }}
            </template>
            <CDropdownItem @click="PrintShipmentList()">
              {{ $t('Order.PrintDefaultShipmentList') }}
            </CDropdownItem>
            <CDropdownItem v-if="Features.includes('ContentPickingList')" @click="PrintShipmentContentList()">
              {{ $t('Order.PrintShipmentList_Content') }}
            </CDropdownItem>
          </CDropdown>
          <CDropdown size="sm" color="dark" placement="bottom-start" class="d-inline-block mr-2">
            <template #toggler-content>
              {{ $t('Order.ExportLogisticsList.Title') }}
            </template>
            <CDropdownItem @click="ExportLogisticsList('TCAT_CLIENT')">
              {{ $t('Order.ExportLogisticsList.TCAT_CLIENT') }}
            </CDropdownItem>
            <CDropdownItem @click="ExportLogisticsList('TCAT_CLIENT_NEW')">
              {{ $t('Order.ExportLogisticsList.TCAT_CLIENT_NEW') }}
            </CDropdownItem>
            <CDropdownItem @click="ExportLogisticsList('TCAT')">
              {{ $t('Order.ExportLogisticsList.TCAT') }}
            </CDropdownItem>
            <CDropdownItem @click="ExportLogisticsList('KERRYTJ')">
              {{ $t('Order.ExportLogisticsList.KERRYTJ') }}
            </CDropdownItem>
            <CDropdownItem @click="ExportLogisticsList('HCT')">
              {{ $t('Order.ExportLogisticsList.HCT') }}
            </CDropdownItem>
            <CDropdownItem @click="ExportLogisticsList('DPEX')">
              {{ $t('Order.ExportLogisticsList.DPEX') }}
            </CDropdownItem>
            <CDropdownItem @click="ExportLogisticsList('UNIMART')">
              {{ $t('Order.ExportLogisticsList.UNIMART') }}
            </CDropdownItem>
            <CDropdownItem @click="ExportLogisticsList('JENJAN')">
              {{ $t('Order.ExportLogisticsList.JENJAN') }}
            </CDropdownItem>
          </CDropdown>
          <CButton color="light" size="sm" class="mr-2" @click="ChooseIndex = [];CheckAllValue = false">
            {{ $t('Global.Cancel') }}
          </CButton>
          <CButton class="d-none" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
        </div>
      </CAlert>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol md="8">
            <h5 class="mb-0">{{ $t('Navigation.Order/Shipment') }}</h5>
          </CCol>
          <CCol md="4" class="text-md-right">
            <CButton color="success" variant="outline" class="mr-2" @click="CommonUseModel = true">
              <CIcon size="sm" class="mr-1" name="cil-plus"/> {{ $t('Global.Add') }}{{ $t('Logistics.CommonUseLogistics') }}
            </CButton>
            <CDropdown color="info" shape="pill" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Order.OrderAction') }}
              </template>
              <CDropdownHeader>
                {{ $t('Order.BulkImport') }}
              </CDropdownHeader>
              <CDropdownItem @click="BulkImportLogisticsData()">
                {{ $t('Order.LogisticsTracking.BulkImport') }}
              </CDropdownItem>
              <CDropdownHeader>
                {{ $t('Logistics.LogisticsSystem.HCT-LOGISTICS') }}
              </CDropdownHeader>
              <CDropdownItem @click="TransReport('hct-logistics')">
                {{ $t('Order.TransReport') }}
              </CDropdownItem>
              <CDropdownHeader>
                {{ $t('Logistics.LogisticsSystem.KERRYTJ-LOGISTICS') }}
              </CDropdownHeader>
              <CDropdownItem @click="TransReport('kerrytj-logistics')">
                {{ $t('Order.TransReport') }}
              </CDropdownItem>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CTabs :active-tab="CurrentTabIndex" fade addTabsWrapperClasses="tab-info border-0 p-0" addNavClasses="border-top-0 border-left-0 border-right-0" @update:activeTab="ChangeTab">
          <CTab v-for="(Nav, Index) in LogisticsTypeNavbar" :key="Nav.Code" :title="Nav.Name ? Nav.Name : $t('Logistics.LogisticsMethods.' + Nav.Code)">
            <CCard class="border-0">
              <CCardHeader class="w-100 overflow-auto px-2">
                <CRow class="text-nowrap">
                  <CCol col="12">
                    <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
                      <CIcon name="cil-filter"/>
                    </CButton>
                    <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
                      {{ $t('Order.Filter.DataTime') }}: {{
                        SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD') : '--'
                      }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD') : '--' }}
                      <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                    </CButton>
                    <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('ReceiverDate')">
                      {{ $t('Order.Filter.ReceiverDate') }}: {{
                        SearchFilter.StartReceiverDate && SearchFilter.EndReceiverDate ? $dayjs(SearchFilter.StartReceiverDate).format('YYYY-MM-DD') + ' ~ ' + $dayjs(SearchFilter.EndReceiverDate).format('YYYY-MM-DD') : '--' }}
                      <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                    </CButton>
                    <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('LogisticsStatus')">
                      {{ $t('Order.LogisticsStatus') }}:
                      {{ SearchFilter.LogisticsStatus.map(item => item.label).join(',') || '--' }}
                      <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                    </CButton>
                    <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('PerPage')">
                      {{ $t('Order.Filter.PerPage') }}: {{ Pagination.PerPage === 'ALL' ? '全部' : Pagination.PerPage }} {{ Pagination.PerPage !== 'ALL' && $t('Global.OrderUnit') || '' }}
                      <CIcon size="sm" class="ml-1" name="cil-pencil"/>
                    </CButton>
                    <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
                      <CIcon size="sm" name="cil-x"/>
                    </CButton>
                    <CButton v-if="Index > 2" size="sm" color="light" class="px-3 mr-2" shape="pill" @click="RemoveLogisticsTypeNavbar(Nav.Code)">
                      <CIcon size="sm" class="mr-1" name="cil-trash"/> {{ $t('Global.Remove') }}{{ $t('Logistics.CommonUseLogistics') }}
                    </CButton>
                  </CCol>
                </CRow>
              </CCardHeader>
              <CCardBody class="p-0">
                <CAlert v-if="$store.getters.orderStatusStatistics.EnableShipment.TotalItems > 200" class="m-3" color="warning" :fade="false" :close-button="true">
                  <i class="fas fa-info-circle mr-2" /> 目前顯示訂單數量過多，若需要查詢請篩選條件後進行搜尋。
                </CAlert>
                <CDataTable
                  id="OrderList"
                  :items="List"
                  :fields="Field"
                  :loading="Loading"
                  :noItemsView="noItemsView"
                  addTableClasses="small text-nowrap"
                  responsive
                  hover
                >
                  <template #Checkbox-header>
                    <label class="mb-0">
                      <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
                    </label>
                  </template>
                  <template #Checkbox="{item}">
                    <td class="text-center">
                      <label class="mb-0">
                        <input type="checkbox" :id="'Checkbox-List-' + item.OrderNum" :value="item.OrderNum"
                               v-model="ChooseIndex">
                      </label>
                    </td>
                  </template>
                  <template #OrderNum="{item}">
                    <td>
                      <a href="#" @click="OpenWindow(`/order/detail/${item.OrderNum}`)" class="text-info">
                        {{ item.OrderNum }}
                      </a>
                      <p class="mb-0">
                        <CBadge class="mr-1" :color="(item.UserID === '0' ? 'warning' : 'success')">{{ (item.UserID === '0' ? '訪客' : '會員') }}</CBadge>
                        <CBadge v-if="typeof item.MemberInfo.Country !== 'undefined' && !item.MemberInfo.Country.includes('TW')" class="mr-1" color="danger">海外</CBadge>
                        <template v-if="Array.isArray(item.Variable.Tags)">
                          <CBadge v-for="Tag in item.Variable.Tags" :key="Tag" class="mr-1" color="danger">{{ $i18n.messages[$i18n.locale].Tags[Tag] ? $i18n.messages[$i18n.locale].Tags[Tag] : Tag }}</CBadge>
                        </template>
                      </p>
                    </td>
                  </template>
                  <template #OrderInfo="{item}">
                    <td>
                      <p class="mb-0"><b>{{ $t('Order.CreateTime') }}</b>: {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}</p>
                      <p class="mb-0">
                        <b>{{ $t('Order.Origin') }}</b>: {{ $t('Order.OriginOptions.' + item.OriginType) }}<span v-if="item.OriginType !== 'Default'">({{ item.OriginPage ? item.OriginPage : '' }})</span>
                      </p>
                    </td>
                  </template>
                  <template #Buyer="{item}">
                    <td>
                      <p class="mb-0"><b>{{ $t('Order.Buyer') }}</b>:
                        <a v-if="item.UserID !== '0'" href="javascript:void(0)" @click="OpenUserModal(item.UserID)" class="text-info">
                          {{ item.MemberInfo.Name }}
                        </a>
                        <span v-else>{{ item.MemberInfo.Name }}</span>
                      </p>
                      <p class="mb-0"><b>{{ $t('Order.Phone') }}</b>: {{ item.MemberInfo.Phone }}</p>
                    </td>
                  </template>
                  <template #Amount="{item}">
                    <td>
                      <p class="mb-0"><b>{{ $t('Order.RealAmount') }}</b>: {{ $store.state.currency + '$' }}{{ item.RealAmount }}</p>
                      <p class="mb-0"><b>{{ $t('Order.Amount') }}</b>: <span
                        class="text-danger">{{ $store.state.currency + '$' }}{{ item.TotalAmount }}</span></p>
                    </td>
                  </template>
                  <template #Note="{item}">
                    <td>
                      <p v-if="item.MemberInfo.ReceiverDate" class="mb-0">
                        <b>{{ $t('Order.Filter.ReceiverDate') }}</b>: {{ $dayjs(item.MemberInfo.ReceiverDate).format('YYYY-MM-DD') }}
                      </p>
                      <p class="mb-0 text-truncate" style="max-width: 180px;">
                        <b>{{ $t('Order.CustomNote') }}</b>: <span v-c-tooltip="item.Note || ''" :class="{'text-info': !!item.Note}">{{ item.Note || '--' }}</span>
                      </p>
                      <p class="mb-0 text-truncate" style="max-width: 180px;">
                        <b>{{ $t('Order.ShipmentNote') }}</b>: <span v-c-tooltip="item.Variable.ShipmentNote || ''" :class="{'text-info': !!item.Variable.ShipmentNote}">{{ item.Variable.ShipmentNote || '--'  }}</span>
                      </p>
                    </td>
                  </template>
                  <template #PaymentAndLogisticsType="{item}">
                    <td>
                      <p class="mb-0"><b>{{ $t('Order.PaymentType') }}</b>: {{ $t('Payment.PaymentMethods.' + item.Payment.Method) }}({{ $t('Payment.PaymentSystem.' + item.Payment.System) }})</p>
                      <p class="mb-0"><b>{{ $t('Order.LogisticsType') }}</b>: {{ $t('Logistics.LogisticsMethods.' + item.Logistics.Method) }}({{ $t('Logistics.LogisticsSystem.' + item.Logistics.System) }})</p>
                    </td>
                  </template>
                  <template #PaymentType="{item}">
                    <td>
                      {{ $t('Payment.PaymentMethods.' + item.Payment.Method) }}({{ $t('Payment.PaymentSystem.' + item.Payment.System) }})
                    </td>
                  </template>
                  <template #LogisticsType="{item}">
                    <td>
                      {{ $t('Logistics.LogisticsMethods.' + item.Logistics.Method) }}({{ $t('Logistics.LogisticsSystem.' + item.Logistics.System) }})
                    </td>
                  </template>
                  <template #PaymentStatus="{item}">
                    <td>
                      <CBadge :color="StatusClassOption[item.Status.Payment]">
                        {{ $t('Order.PaymentStatusList.' + item.Status.Payment) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #LogisticsStatus="{item}">
                    <td>
                      <CBadge :color="StatusClassOption[item.Status.Logistics]">
                        {{ $t('Order.LogisticsStatusList.' + item.Status.Logistics) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #OrderStatus="{item}">
                    <td>
                      <CBadge :color="StatusClassOption[item.Status.Order]">
                        {{ $t('Order.OrderStatusList.' + item.Status.Order) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Action="{item}">
                    <td>
                      <CButton v-if="(item.Status.Payment === 'Y' || item.Status.Payment === 'A') && item.Status.Logistics === 'P' && item.Logistics.System !== 'CUSTOM'" class="mr-1" color="info" size="sm" v-c-tooltip="$t('Order.CreateLogisticsOrder')" @click="OpenCreateLogisticsOrderModal(item.OrderNum, item.Logistics.System)">
                        <CIcon name="cil-truck" class="c-icon-custom-size" />
                      </CButton>
                      <CButton v-if="(item.Status.Logistics === 'U' || item.Status.Logistics === 'H') && item.Logistics.System !== 'CUSTOM'" color="light" class="mr-1" size="sm" v-c-tooltip="$t('Order.PrintSingleLabel')" @click="RedirectSinglePrintShipmentLabel(item)">
                        <CIcon name="cil-print" class="c-icon-custom-size" />
                      </CButton>
                    </td>
                  </template>
                </CDataTable>
              </CCardBody>
              <CCardFooter>
                <CPagination :activePage.sync="Pagination.Page" :pages.sync="Pagination.TotalPages" @update:activePage="ChangePage()"/>
              </CCardFooter>
            </CCard>
          </CTab>
        </CTabs>
      </CCardBody>
    </CCard>
    <component :is="Component" :Toggle.sync="ToggleModal" :OrderData.sync="SelectOrderData" :AllCondition.sync="AllCondition" :SelectSystem.sync="SelectSystem" :OrderNum.sync="SelectOrderNum" :UserID="UserID" :UserType="UserType" @Success="DelayReload()" />
    <CModal id="FilterModel" class="ActionModel" :size="(ShowFilter !== 'All' ? '' : 'lg')" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit"
            @update:show="GetList()">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="date" :model-config="{timeAdjust: '00:00:00'}" :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="date" :model-config="{timeAdjust: '23:59:59'}" :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
        </CCol>
        <CCol sm="9">
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().format('YYYY-MM-DD'), $dayjs().add(1, 'day').format('YYYY-MM-DD'))">
            今日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'day').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'))">
            昨日
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(6, 'day').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'))">
            本週
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(2, 'week').format('YYYY-MM-DD'), $dayjs().subtract(1, 'week').format('YYYY-MM-DD'))">
            上週
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().startOf('month').format('YYYY-MM-DD'), $dayjs().endOf('month').format('YYYY-MM-DD'))">
            本月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'), $dayjs().startOf('month').format('YYYY-MM-DD'))">
            上月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(1, 'month').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'))">
            近一個月
          </CButton>
          <CButton size="sm" color="info" shape="pill" class="px-3 mr-2 mb-2" @click="ApplyTimeRange($dayjs().subtract(3, 'month').format('YYYY-MM-DD'), $dayjs().format('YYYY-MM-DD'))">
            近三個月
          </CButton>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'ReceiverDate'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.ReceiverDate') }}{{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartReceiverDate" mode="date" :max-date="$dayjs(SearchFilter.EndReceiverDate || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.ReceiverDate') }}{{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndReceiverDate" mode="date" :min-date="$dayjs(SearchFilter.StartReceiverDate || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'LogisticsStatus'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.LogisticsStatus') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.LogisticsStatus"
            v-model="SearchFilter.LogisticsStatus"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="LogisticsStatusList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'PerPage'" horizontal :label="$t('Order.Filter.PerPage')" :options="PerPageOption" v-model="Pagination.PerPage" :value.sync="Pagination.PerPage" :placeholder="$t('Global.PleaseSelect')"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangeTab(CurrentTabIndex)" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="CommonUseModel" class="ActionModel" :show.sync="CommonUseModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="info" :fade="false" class="mb-4">
        <i class="fas fa-info-circle mr-2" />新增後將會存於當前裝置上的本地存儲(LocalStorage)。
      </CAlert>
      <CSelect horizontal :label="$t('Order.LogisticsType')" :options="LogisticsTypeList" v-model="CurrentNavbar" :value.sync="CurrentNavbar" :placeholder="$t('Global.PleaseSelect')"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Add') }}{{ $t('Logistics.CommonUseLogistics') }}</h5>
          <CButtonClose @click="CommonUseModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#CommonUseModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="CommonUseModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CommonUseModelSubmit" @click="AddLogisticsTypeNavbar()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "出貨作業管理"
}
}
</route>

<script>
export default {
  name: 'OrderList',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      Component: null,
      List: [],
      Features: [],
      StatisticsSwitch: 'Total',
      SubTotalStatistics: {},
      TotalStatistics: {},
      ShowFilter: 'All',
      SearchFilterType: null,
      SearchFilter: {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).subtract(7, 'day').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'day').format('YYYY-MM-DD')).unix() * 1000,
        StartReceiverDate: '',
        EndReceiverDate: '',
        LogisticsStatus: [],
        'Logistics.Type': '',
        'Logistics.Method': ''
      },
      CurrentTabIndex: 0,
      AllCondition: false,
      AlertModel: false,
      FilterModel: false,
      CommonUseModel: false,
      CurrentNavbar: '',
      Pagination: {
        Page: 1,
        PerPage: 'ALL',
        TotalItems: 0,
        TotalPages: 0
      },
      LogisticsTypeNavbar: [
        {
          Name: this.$t('Global.All'),
          Code: 'ALL',
          Conditions: {}
        },
        {
          Name: this.$t('Logistics.ShipmentType.EXPRESS'),
          Code: 'EXPRESS',
          Conditions: {
            'Logistics.Type': 'EXPRESS',
            'Logistics.Method': undefined
          }
        },
        {
          Name: this.$t('Logistics.ShipmentType.SUPERSTORE'),
          Code: 'SUPERSTORE',
          Conditions: {
            'Logistics.Type': 'SUPERSTORE',
            'Logistics.Method': undefined
          }
        }
      ],
      ToggleModal: false,
      SelectOrderNum: '',
      SelectOrderData: {},
      SelectSystem: '',
      StatusClassOption: {
        'P': 'dark',
        'R': 'light',
        'RF': 'light',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'X': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
        'A': 'primary',
      },
      ChooseIndex: [],
      CheckAllValue: false,
      Order: 'nto',
      SearchAllTimeRange: true,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Order.NoResults'),
        noItems: this.$t('Order.NoItems')
      },
      PageList: [
        { label: this.$t('Offer.OfficialWeb'), value: 'Default' }
      ],
      UserID: '',
      UserType: ''
    }
  },
  computed: {
    Field() {
      return [
        {key: 'Checkbox', label: ''},
        {key: 'OrderNum', label: this.$t('Order.OrderNum')},
        {key: 'OrderInfo', label: this.$t('Order.Info')},
        {key: 'Buyer', label: this.$t('Order.Buyer')},
        {key: 'Amount', label: this.$t('Order.Amount')},
        {key: 'Note', label: this.$t('Order.Note')},
        {key: 'PaymentAndLogisticsType', label: this.$t('Order.PaymentAndLogisticsTypeSystem')},
        {key: 'PaymentStatus', label: this.$t('Order.PaymentStatus')},
        {key: 'LogisticsStatus', label: this.$t('Order.LogisticsStatus')},
        {key: 'OrderStatus', label: this.$t('Order.OrderStatus')},
        {key: 'Action', label: ''},
      ]
    },
    LogisticsStatusList() {
      return Object.keys(this.$t('Order.LogisticsStatusList')).filter(status => status === 'P' || status === 'H').map(status => {
        return {
          value: status,
          label: this.$t('Order.LogisticsStatusList')[status]
        }
      })
    },
    LogisticsTypeList() {
      return Object.keys(this.$t('Logistics.LogisticsMethods')).map(status => {
        return {
          value: status,
          label: this.$t('Logistics.LogisticsMethods')[status]
        }
      })
    },
    PerPageOption() {
      return [
        {
          value: 'ALL',
          label: '不分頁'
        },
        ...Object.keys(this.$t('Order/List.PerPageOption')).map(option => {
          return {
            value: option,
            label: this.$t('Order/List.PerPageOption')[option]
          }
        })
      ]
    },
  },
  created() {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    const LogisticsTypeNavbarSession = localStorage.getItem('LogisticsTypeNavbar') ? JSON.parse(localStorage.getItem('LogisticsTypeNavbar')) : []
    this.LogisticsTypeNavbar.push(...LogisticsTypeNavbarSession)
    this.Features = PermissionSession.Features.Order || []
  },
  mounted() {
    if (this.$store.getters.orderStatusStatistics.EnableShipment.TotalItems <= 200) {
      this.$Progress.start()
      return this.Init().then(() => {
        this.$Progress.finish()
      }).catch((err) => {
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    }
  },
  methods: {
    Init() {
      const Executes = [
        this.GetList()
      ]
      return Promise.all(Executes)
    },
    GetList() {
      const Query = {
        Taxonomy: 'ProductOrder',
        Order: 'nto',
        'Status.Payment': ['Y', 'A'],
        'Status.Logistics': ['P', 'H'],
        'Status.Order': ['Y']
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        if (this.SearchFilter[param]) {
          Query[param] = this.SearchFilter[param]
        }
      })
      Query.StartTime = this.$dayjs(Query.StartTime).unix()
      Query.EndTime = this.$dayjs(Query.EndTime).unix()
      if (this.SearchFilter.StartReceiverDate && this.SearchFilter.EndReceiverDate) {
        Query.StartReceiverDate = this.$dayjs(this.SearchFilter.StartReceiverDate).format('YYYY-MM-DD')
        Query.EndReceiverDate = this.$dayjs(this.SearchFilter.EndReceiverDate).format('YYYY-MM-DD')
      }
      if (this.SearchFilter.LogisticsStatus.length > 0) {
        Query['Status.Logistics'] = this.SearchFilter.LogisticsStatus.map(item => item.value)
      }
      this.CheckAllValue = false
      this.ChooseIndex = []
      this.List = []
      this.Loading = true
      this.FilterModel = false
      let Action = this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/order/systemList',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
          ...Query
        }
      })
      if (this.Pagination.PerPage !== 'ALL') {
        Query.Page = this.Pagination.Page || 1
        Query.PerPage = this.Pagination.PerPage
        Action = this.$store.dispatch('InnerRequest', {
          endPoint: '/service',
          url: '/order/list',
          method: 'post',
          data: {
            Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
            ...Query
          }
        })
      }
      return Action.then(({ data }) => {
        this.Loading = false
        this.List = data.Data.map(item => {
          item.OriginType = item.Variable?.CartType ? item.Variable?.CartType.split('-')[0] : 'Default'
          item.OriginPageSlug = !item.Variable?.CartType.includes('Default') ? (item.Variable?.CartType || '').replace('SinglePage-', '') : '--'
          item.OriginPage = item.Variable?.CartTypeName || (item.Variable?.CartType || '').replace('SinglePage-', '')
          return item
        })
        if (this.Pagination.Page !== 'ALL') {
          this.Pagination = {
            Page: data.Pagination.Page,
            PerPage: data.Pagination.PerPage,
            TotalItems: data.Pagination.TotalItems,
            TotalPages: data.Pagination.TotalPages
          }
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      this.GetList()
    },
    ChangeTab(tabIndex) {
      if (this.CurrentTabIndex !== tabIndex) {
        this.Pagination.Page = 1
        this.Pagination.PerPage = 'ALL'
      }
      this.CurrentTabIndex = tabIndex
      this.SearchFilter = {
        ...this.SearchFilter,
        StartTime: this.SearchFilter.StartTime,
        EndTime: this.SearchFilter.EndTime,
        LogisticsStatus: this.SearchFilter.LogisticsStatus,
        ...this.LogisticsTypeNavbar[tabIndex || this.CurrentTabIndex || 0].Conditions
      }
      if (tabIndex === 0) {
        this.SearchFilter['Logistics.Type'] = undefined
        this.SearchFilter['Logistics.Method'] = undefined
      }
      if (tabIndex > 2) {
        this.SearchFilter['Logistics.Type'] = undefined
      }
      this.GetList()
    },
    DelayReload () {
      this.Loading = true
      setTimeout(async () => {
        await this.$store.dispatch('GetOrderStatus')
        this.GetList()
      }, 3000)
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.OrderNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      if (id === 'OrderNum') {
        newTag = newTag.trim().toUpperCase()
      }
      this.SearchFilter[id].push(newTag)
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD')).subtract(1, 'month').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'day').format('YYYY-MM-DD')).unix() * 1000,
        LogisticsStatus: [],
        StartReceiverDate: '',
        EndReceiverDate: '',
        'Logistics.Type': '',
        'Logistics.Method': '',
      }
      this.ChooseIndex = []
      this.ChangeTab(this.CurrentTabIndex)
    },
    OpenWindow(_link) {
      return window.open(_link, '_blank');
    },
    OpenCreateLogisticsOrderModal (orderNum, system) {
      try {
        this.Component = require('@/components/order/CreateShipment/' + system.toLowerCase()).default
      } catch (error) {
        this.Component = require('@/components/order/CreateLogisticsOrder').default
      }
      setTimeout(() => {
        this.SelectOrderNum = orderNum
        this.ToggleModal = true
      }, 300)
    },
    PrintShipmentList () {
      this.Submit = true
      this.$Progress.start()
      if (this.ChooseIndex.length === 0) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportShipmentList',
        method: 'post',
        data: {
          orderIDs: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/pdf'})
        return window.open(URL.createObjectURL(Data), '_blank')
      }).catch((err) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    PrintShipmentContentList () {
      this.Submit = true
      this.$Progress.start()
      if (this.ChooseIndex.length === 0) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportShipmentContentList',
        method: 'post',
        data: {
          orderIDs: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/pdf'})
        return window.open(URL.createObjectURL(Data), '_blank')
      }).catch((err) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    BulkCreateShipmentOrder(system) {
      if (this.ChooseIndex.length === 0) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      try {
        this.SelectOrderNum = this.ChooseIndex
        this.Component = () => import('@/components/order/BulkCreateShipment/' + system)
        setTimeout(() => {
          this.ToggleModal = true
        }, 300)
      } catch (error) {
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error,
          type: 'error'
        })
      }
    },
    BulkPrintLabelModal(system) {
      if (this.ChooseIndex.length === 0) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      try {
        this.SelectOrderNum = this.ChooseIndex
        this.Component = () => import('@/components/order/PrintLabel/' + system)
        setTimeout(() => {
          this.ToggleModal = true
        }, 300)
      } catch (error) {
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + '此配送方式或系統不支援列印標籤',
          type: 'error'
        })
      }
    },
    BulkPrintLabel(system) {
      this.Submit = true
      this.$Progress.start()
      if (this.ChooseIndex.length === 0 || this.ChooseIndex === false) {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      const PayCenterOrderNo = this.List.filter(item => typeof item.LogisticsData?.OrderNo !== 'undefined' && this.ChooseIndex.includes(item.OrderNum) && item.LogisticsData?.LabelImage !== 'Not supported currently.').map(item => item.LogisticsData.OrderNo)
      if (PayCenterOrderNo.length === 0) {
        this.$Progress.finish()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.NoOrderPrint'),
          type: 'warn'
        })
        return false
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportShipmentLabel',
        method: 'post',
        data: {
          System: system,
          OrderData: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/pdf'})
        return window.open(URL.createObjectURL(Data), '_blank')
      }).catch((err) => {
        throw err
      })
    },
    RedirectSinglePrintShipmentLabel(orderData) {
      this.Submit = true
      this.$Progress.start()
      this.Submit = false
      this.$Progress.finish()
      switch (orderData.Logistics.System) {
        case 'ECPAY-LOGISTICS':
          return window.open(`/api/order/redirectPrintShipmentLabel?Token=${localStorage.getItem('XX-CSRF-Token')}&System=${orderData.Logistics.System.toUpperCase()}&Method=${orderData.Logistics.Method.toUpperCase()}&OrderData=${orderData.LogisticsData.OrderNo}`, '_blank')
        default:
          if (orderData.LogisticsData.LabelImage && orderData.LogisticsData.LabelImage.includes('http')) {
            return window.open(orderData.LogisticsData.LabelImage, '_blank')
          } else if (orderData.Logistics.System !== 'CUSTOM') {
            return window.open(`/api/order/redirectPrintShipmentLabel?Token=${localStorage.getItem('XX-CSRF-Token')}&System=${orderData.Logistics.System.toUpperCase()}&Method=${orderData.Logistics.Method.toUpperCase()}&OrderData=${orderData.LogisticsData.OrderNo}`, '_blank')
          } else {
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Warning'),
              text: this.$t('Order.NoConditionOrderPrint'),
              type: 'warn'
            })
          }
          break
      }
    },
    RedirectPrintShipmentLabel(system, method) {
      if (this.ChooseIndex.length === 0 || this.ChooseIndex === false) {
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      this.$Progress.start()
      const PayCenterOrderNo = this.List.filter(item => typeof item.LogisticsData?.OrderNo !== 'undefined' && this.ChooseIndex.includes(item.OrderNum)).map(item => item.LogisticsData.OrderNo)
      this.$Progress.finish()
      if (PayCenterOrderNo.length > 0) {
        return window.open(`/api/order/redirectPrintShipmentLabel?Token=${localStorage.getItem('XX-CSRF-Token')}&System=${system.toUpperCase()}&Method=${method.toUpperCase()}&OrderData=${PayCenterOrderNo.join(',')}`, '_blank')
      } else {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.NoOrderPrint'),
          type: 'warn'
        })
      }
    },
    BulkImportLogisticsData () {
      this.Component = () => import('@/components/order/BulkImportLogisticsData')
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    ExportLogisticsList(type) {
      if (this.ChooseIndex.length === 0) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Order.ShipmentListAtLeastOne'),
          type: 'warn'
        })
        return false
      }
      this.Submit = true
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/order/exportLogisticsList',
        method: 'post',
        data: {
          LogisticsType: type,
          orderIDs: this.ChooseIndex
        }
      }).then((response) => {
        this.Submit = false
        this.$Progress.finish()
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `${this.$t(`Order.ExportLogisticsList.${type}`)}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Submit = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
        throw err
      })
    },
    TransReport (system) {
      this.Component = () => import('@/components/order/TransReport')
      this.SelectSystem = system
      setTimeout(() => {
        this.ToggleModal = true
      }, 300)
    },
    ApplyTimeRange (StartTime, EndTime, FilterParameters) {
      if (FilterParameters) {
        this.SearchFilter.ReceiverDate = this.$dayjs(StartTime).format('YYYY-MM-DD').toString()
      } else {
        this.SearchFilter.StartTime = this.$dayjs(StartTime).unix() * 1000
        this.SearchFilter.EndTime = this.$dayjs(EndTime).unix() * 1000
      }
    },
    OpenUserModal(userID) {
      this.Component = () => import('@/components/member/UserModel')
      this.UserID = userID
      this.UserType = 'Read'
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    AddLogisticsTypeNavbar () {
      const LogisticsTypeNavbar = localStorage.getItem('LogisticsTypeNavbar') ? JSON.parse(localStorage.getItem('LogisticsTypeNavbar')) : []
      if (LogisticsTypeNavbar.find(item => item.Code === this.CurrentNavbar)) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: '已存在相同配送方式',
          type: 'warn'
        })
        return
      }
      const Data = {
        Code: this.CurrentNavbar,
        Conditions: {
          'Logistics.Method': this.CurrentNavbar
        }
      }
      LogisticsTypeNavbar.push(Data)
      localStorage.setItem('LogisticsTypeNavbar', JSON.stringify(LogisticsTypeNavbar))
      this.LogisticsTypeNavbar.push(Data)
      this.CurrentNavbar = ''
      this.CommonUseModel = false
    },
    RemoveLogisticsTypeNavbar (code) {
      const LogisticsTypeNavbar = localStorage.getItem('LogisticsTypeNavbar') ? JSON.parse(localStorage.getItem('LogisticsTypeNavbar')) : []
      const RemoveIndex = LogisticsTypeNavbar.findIndex(item => item.Code === code)
      LogisticsTypeNavbar.splice(RemoveIndex, 1)
      localStorage.setItem('LogisticsTypeNavbar', JSON.stringify(LogisticsTypeNavbar))
      this.LogisticsTypeNavbar.splice(this.CurrentTabIndex, 1)
    }
  }
}
</script>

<style>
#OrderList thead th:first-child {
  text-align: center;
}

#OrderList tbody td:first-child {
  vertical-align: middle;
}
</style>
